import adSettings from '../config/adsettings';
import { alterSubdomain } from '../config/domain';

const getAdCfgSessionKey = () => `ad_cfg_${alterSubdomain(adSettings.hostName)}`;

const getSessionProp = () => {

  const key = getAdCfgSessionKey();
  const val = sessionStorage.getItem(key);

  return val ? JSON.parse(val) : undefined;
};

const setSessionProp = (value: any) => {
  const key = getAdCfgSessionKey();

  if (typeof value === 'object' && Object.keys(value).length > 0) {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.warn(`Couldn not set session storage for `, key, value);
    }
  }
};

const session = {
  get: getSessionProp,
  set: setSessionProp,
};

export default session;
