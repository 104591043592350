import {GPT_EVENTS} from "./events";
import {AdStats} from "./adstats";
import {
    isDebug,
    random
} from "../helpers/utils";
import { hostIsSpecificSubdomain, getSiteFromLocation, getSubdomainAdwatchName } from "../config/domain";

import {getDevice} from "../config/device";
import log from '../helpers/log';
import {COLLECTOR} from "./constants";
import statssettings from "./statssettings";



/**
 * GPT events we want to act on for collecting slots
 * Format: {
 *     event: {string} GPT Event
 *     decorate: {func|undefined} Optional decorate function to add more specific stats
 * }
 * @type {{event: string}[]}
 */
const gptEventHandlers = [
    {
        event: GPT_EVENTS.slotRequested
    }
];

const getHandlersForEvent = gptEvent => gptEventHandlers.filter( handler => handler.event === gptEvent);

/**
 * With valid targeting we mean a valid targeting that also contain values
 * @param targeting {array}
 * @return {boolean}
 */
const isValidTargeting = targeting => Array.isArray(targeting) && targeting.length;

/**
 * Return the position for a slot (i.e. resonsive_1)
 * @param slot {object} A gpt slot object
 * @return {string|undefined}
 */
const getPositionFromSlot = slot => {
    const targeting = slot.getTargeting('pos')
    return isValidTargeting(targeting) ? targeting[0] : undefined
}

const slotStat = (position, gptEvent) => ({placement: position, gptEvent})

const handlerHasDecorator = handler => handler.decorate && typeof handler.decorate === 'function'

/**
 * Handler for GPT events
 * @param targetEvent {string} The GPT event we want to watch for
 * @return {function} Returns a gpt event listener tied to {targetEvent}
 */
const onGptEvent = targetEvent => gptEvent => {
    try {
        const pos = getPositionFromSlot(gptEvent.slot)
        const stat = slotStat(pos, targetEvent);
        const handlers = getHandlersForEvent(targetEvent)
        const stats = handlers.map( handler => handlerHasDecorator(handler) ? handler.decorate(stat) : stat);

        window.adStats.add(stats);
    }
    catch (e) {
        log(`onGptEvent:: Unable to process stats for ${targetEvent}`)
    }
}

const addEventListeners = () => {
    googletag.pubads().addEventListener(GPT_EVENTS.slotRequested, onGptEvent(GPT_EVENTS.slotRequested));
}

const removeEventListeners = () => {
    googletag.pubads().removeEventListener(GPT_EVENTS.slotRequested, onGptEvent(GPT_EVENTS.slotRequested));
}

const isWithinSamplerate = () => random(0,100) <= statssettings.get()['samplerate'];
const isAllowedToCollect = () => isDebug() || (statssettings.get()['enabled'] && isWithinSamplerate());



let pushInterval;
export const collector = {
    start: (options = {}) => {

        statssettings.get(options); // TODO: Fix to a nicer solution

        const whitelistedBlogs = statssettings.get()['whitelistedBlogs'];
        const isBlog = hostIsSpecificSubdomain();

        if (isBlog && !whitelistedBlogs.includes(window.location.host)) {
            log(`adWatch:: ${location.host} is a not whitelisted subdomain`)
        }
        else if ( isAllowedToCollect()) {
            const site = isBlog ? getSubdomainAdwatchName() : getSiteFromLocation();
            const device = getDevice();
            window.adStats = new AdStats(site, device)
            window.googletag.cmd.push(addEventListeners)

            pushInterval = setInterval( window.adStats.push, COLLECTOR.pushInterval )
        }
    },
    stop: () => {
        removeEventListeners();
        clearInterval(pushInterval);
    }
}