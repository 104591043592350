import AdsManager from './AdsManager';
import { configureForDevelopment } from './placement/local';
import { enableGrowthbook, getGrowthbookGroupVideo } from './abtest/growthbook';
import adSettings from './config/adsettings';
import { getAdScheduleId } from './config/adschedule';
import ppid from './config/ppid';
import adConfig from './config/adslots';
import initConsent from './consent/consent';
import initGpt from './googletag/googletag';
import log from './helpers/log';
import { collector } from './adstats/collector';
import waitFor from './helpers/waitfor';
import { initAdResources } from './resource/resource';

let processedIds: string[] = [];
const processed = { divIds: [] as string[] };
const hasValidAdsUniqueId = (id: string | undefined) => typeof id !== 'undefined' && !processedIds.includes(id);

/**
 * Initializes the loading of ads on a page.
 * @param uniqueId The unique ID appended to placements div-ids.
 * @param options Custom settings, e.g., { dfpSection = "..." }.
 * @returns A Promise that resolves once the initialization is complete.
 */
const initializeAds = async (uniqueId: string = window.adsUniqueId, options: any = {}): Promise<void> => {
  try {
    if (!hasValidAdsUniqueId(uniqueId)) {
      processedIds.includes(uniqueId) && console.warn(`Ads:: already processed ${uniqueId}`);
  
      log('Ads:: Valid uniqueId not set yet, waiting for updated window.adsUniqueId ...');
      await waitFor(() => hasValidAdsUniqueId(window.adsUniqueId), { timeout: 5000, errorMessage: `Timeout: Valid adsUniqueId is missing - ${uniqueId}` });
      uniqueId = window.adsUniqueId;
    }
    log('Ads:: uniqueId/window.adsUniqueId: ', uniqueId);

    processedIds.push(uniqueId);
    adSettings.refresh();

    const { dfpSection } = options;

    log('Get config for ', adSettings.hostName, adSettings.device, {...adSettings});

    let config: any = await adConfig.getModifiedConfig(
      adSettings.hostName,
    );

///////////////////
//////////////////////////////////////////
/////////////

    config = adConfig.addExtraPlacements(config, 'intext', processed);
    config = adConfig.addExtraPlacements(config, 'responsive', processed);

    let filteredAds: any = adSettings.filterAds
      ? adConfig.filter(config, adSettings.isNativeAd)
      : config;

    log('filteredAds incl only no-processed', filteredAds);

    await initConsent();

    initGpt();

    collector.start(config?.statscollector);

    await enableGrowthbook();

    AdsManager.init(filteredAds, ppid.get(), uniqueId, dfpSection);
  } catch (error) {
    console.error('Error initializing ads:', error);
  }
};

// get attribute ads-conf from current script
const adsPlatformConfJson = document.getElementById('ads-manager').getAttribute('data-ads-conf');

initAdResources(adsPlatformConfJson).then( () => {
  log('AdResources loaded');

  // See initializeAds for parameters to use when calling DFPInit
  window.DFPInit = async (...args: any) => {
      log('window.DFPInit called initializeAds ...');
      initializeAds( ...args);      
  }
  log('window.DFPInit available');

  adSettings.init();
  adSettings.autoloadAds && initializeAds(window.adsUniqueId);

  window.ads = {
    getAdScheduleId,
    getVideoExperimentGroup: getGrowthbookGroupVideo,
    getVideoPpId: ppid.get,
  };  
  log('window.ads available');

}).catch( (error) => {
  console.error('AdResources fail to load', error);
});


// A rough plan for the AdsManager

// Initiliaze Ads

// Verify arguments to InitializeAds

// load global ads settings from current platform, ocelot, recept, labrador, bloggers etc
// - Sitename, displayAds, isNativeAd and more
// - Create ppid

// load Ads Configurations from Ads Microservice,
// - placements script toogles, bundle-version etc

// Load optional AB-test configurations

// Local development setup: placements, sitename etc

// Adjusting ads configuations to match the type of page.
// -Add placement_x on page 2, 3, 4 etc.
// -Remove placements that does not exist on the page, native_ads maybe?

// Wait for Consent library to load, that includes tcf api

// Wait for Load Google adsmanager, aka gpt,  (await initGpt())

// Load Ads testing managment.
// -Sending data to ad-stats used by Grafana

// Loading AdsMangaer that handles placements and adrequests and more

// - Setup ad placement tags
// - Setup display adrequests

// - Load gpt queue and prebid queue

// display Ads
// - Setup (queue) gpt-requests for page
// - Setup (queue) gpt-requests for ad slots
// - (queue) Display ad slots

// - (queue) request for prebid bids for display ads

// - (queue) gpt lazyloading, including machine learning for lazyloading

// - (queue) Scale ads for tablet

// --------------
// Video Helper Module, adsceduleId, ppid, video-verticals, ab-values
// - Waitfor Consent
// - Load sitename logic
// - Load ppid
// - Load gpt queue
// - Load demandmanager/prebid queue
// - Setup gpt-requests for video ads
// - Load MS gpt site settings for path network code and site
// - (queue) request for prebid bids for video ads
// - Expose Video settings to window.ads
// --------------

// 1. Setup Typescript and let build script bundle a file and test to run it, console.log

// 2. Create entry point for AdsManager.
// // window.DFPInit = initializeAds;
// // adSettings.autoloadAds && initializeAds();

// 3. Start add modules according to our plan.
