import waitFor from '../helpers/waitfor';
import log from '../helpers/log';
import { loadConfig, parseAdsConfig, LoadedByAdsLib } from '../config/platform';


let isLoadscriptAvailable = false;
let isTcfapiLoaded = false;
let isPlatformConfigLoaded = false;
let isAdnamiLoaded = false;
let isDemandManagerLoaded = false;
let isGptLoaded = false;

export const resourceTimeout = 20000;

export const isAdResourcesLoaded = () => isTcfapiLoaded && isAdnamiLoaded && isDemandManagerLoaded && isGptLoaded && isPlatformConfigLoaded;

const loadAdnami = (loadedByAdsLib: LoadedByAdsLib) => {
  if(loadedByAdsLib?.adnami) {
    const adsPath = `/common/js/ads`;
    const adnamiPath = 'https://macro.adnami.io/macro/spec/adsm.macro.747590aa-4758-4281-839e-4b9b65def16a.js';
  
    try {
      window.userConsent.loadScript(`${adsPath}/adnami-conf.js`, { async: true, defer: false, strategy: "beforeInteractive" }).then( () => {
        log('AdnamiConfLoaded');
      });
      window.userConsent.loadScript(adnamiPath, { async: true, defer: false, strategy: "beforeInteractive" }).then( () => {
        isAdnamiLoaded = true;
        log('AdnamiLoaded');
      });
    } catch (error) {
      isAdnamiLoaded = true;
      console.error('Adnami not Loaded', error);    
    }  
  } else {
    log('Adnami not loaded by ads-lib');
    isAdnamiLoaded = true;
  }
};

const loadDemandManager = async (loadedByAdsLib: LoadedByAdsLib) => {
  if (loadedByAdsLib?.demandManager) {
    const demandManagerPath = 'https://micro.rubiconproject.com/prebid/dynamic/13012.js';

    try {
      waitFor(() => isPlatformConfigLoaded, { timeout: resourceTimeout, errorMessage: `Timeout: isGlobalConfigLoaded failed ` }).then( () => {
        if(window.adsUseDemandManager === 'true') {
          window.userConsent.loadScript(demandManagerPath, { 
            async: true,
            defer: false,
            strategy: "beforeInteractive",
            referrerPolicy: "strict-origin-when-cross-origin"
          }).then( () => {
            isDemandManagerLoaded = true;
            log('DemandManagerLoaded');
          });  
        } else {
          log('DemandManagerLoaded DONE - not used');
          isDemandManagerLoaded = true;
        }
      });      
    } catch (error) {
      console.error('DemandManager failed to Load', error);
      isDemandManagerLoaded = true;
    }  
  } else {
    log('DemandManager not loaded by ads-lib');
    isDemandManagerLoaded = true;
  }
};

const loadGpt = async (loadedByAdsLib: LoadedByAdsLib) => {
  if (loadedByAdsLib?.gpt) {
    const gptPath = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

    window.userConsent.loadScript(gptPath, { async: true, defer: false, strategy: "beforeInteractive" }).then( () => {
      isGptLoaded = true;
      log('GptLoaded');
    });  
  } else {
    log('GPT not loaded by ads-lib');
    isGptLoaded = true;
  }
};

export const initAdResources = async (adsConfig: string | undefined) => {

  const config = parseAdsConfig(adsConfig);

  // Load consent loadScript function
  waitFor(() => typeof window?.userConsent?.loadScript === 'function', { timeout: resourceTimeout, errorMessage: `Timeout: userConsent.loadscript not available ` }).then(() => {
    isLoadscriptAvailable = true;
    log('Consent loadScript is available');

    // Load platformConfig if available
    loadConfig(config);
    isPlatformConfigLoaded = true;

    loadAdnami(config.loadedByAdsLib);
    loadDemandManager(config.loadedByAdsLib);
    loadGpt(config.loadedByAdsLib);
  });

  // Load tcfapi
  waitFor(() => typeof window?.__tcfapi !== 'undefined', { timeout: resourceTimeout, errorMessage: `Timeout: window.__tcfapi not available ` }).then(() => {
    isTcfapiLoaded = true;
    log('tcfApi is loaded');
  });

  log('waitFor isAdResourcesLoaded ...');

  return await waitFor(isAdResourcesLoaded, { timeout: resourceTimeout, errorMessage: `Timeout: isAdResourcesLoaded failed ` });
};
