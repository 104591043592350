import { isChromeBrowser } from '../config/device';
import { isNil } from '../helpers/utils';

const lazyloadVariation = (): string => {
  return Math.random() < 0.1 ? 'c' : 'a';
};

// Function to set the adsAbTest in session storage
const setAdsAbTestInSessionStorage = (variation: string): void => {
  sessionStorage.setItem('adsAbTest', variation);
};

// Function to get the adsAbTest from session storage
const getAdsAbTestFromSessionStorage = (): string | null => {
  return sessionStorage.getItem('adsAbTest');
};

// Perform the A/B test
export const adsAbTest = (): string => {
  let variation: string | null = getAdsAbTestFromSessionStorage();

  // If the user hasn't been assigned an adsAbTest, assign one and store it
  if (isNil(variation)) {
    variation = lazyloadVariation();
    setAdsAbTestInSessionStorage(variation);
  }

  return variation;
};

export const getConnectionType = (): string => {
  const connection = (navigator as any).connection; // Adjusting for potential absence in types
  if (connection && isChromeBrowser()) {
    return (Math.floor(connection?.rtt / 50) * 50).toString();
  } else {
    return 'other';
  }
};
