import {
  DESKTOP_BREAKPOINT,
  isTablet,
  isFacebookInAppBrowser,
  isApp,
  isCategoryPage,
  isRefferedPage,
  isInstagramInAppBrowser,
  isTikTokInAppBrowser,
  isPinterestInAppBrowser,
  isComingFromPushPushGo,
  isGoogleDiscover,
} from './config/device';
import { scaleAds } from './view/scaleAds';
import { pbjs } from './prebid/index';
import { isDesktop, getDevice } from './config/device';
import { adjustPlacement } from './placement/prod';
import { getHostNameFromSite, isSubDomain, resolveDevSiteName, getDomainFromHostName } from './config/domain';
import { pluck, isNotNil, isString, withOutLazyloadSlots, refreshPrebidAd } from './helpers/utils';
import log from './helpers/log';
import { adsAbTest, getConnectionType } from './abtest/adsAbTest';

/**
 * AdsManager will make life a tad easier for developers to deal
 * with ads as it can feel like a black magic most of the times.
 *
 * We construct and set up ad slots in this class so that the ads are
 * ready to send and receive information to and from Google DFP Ads.
 */
class AdsManager implements IAdsManager {
  public adUnitPath: string;
  public videoAdUnitPath: string;
  public gptIsReady: boolean;
  public Ads: IAdRaw[];
  public adsConfig: IAdsConfig;
  public AdUnits: object[];
  public PrebidAdUnits: object[];
  public listeners: IListener[];
  public uniqueId: string;
  public uniqueIdTemp: string;
  public ppId: string | undefined;
  public pageSlots: { [key: string]: object[] };
  public lazyloadAds: boolean;
  public device: string;
  public usePrebid: boolean;
  public renderMargin: string;

  /**
   * Upon initialization we will prepare instance variables and also
   * bind class methods that we want to make accessible to the api.
   *
   * Also an interval is run immediately to make sure that once googletag
   * api is ready we will also take action.
   */

  // @ts-ignore
  init(
    adsConfig: IAdsConfig,
    _ppId: string | undefined,
    uniqueId: string,
    dfpSection: string | undefined,
  ) {
    log('init', adsConfig);

    this.adsConfig = adsConfig;
    this.adsConfig.dfpSection = this.setDfpSection(dfpSection);
    this.adsConfig.dfpId = this.setDfpId(adsConfig.dfpId);
    this.Ads = adsConfig.ads;

    this.adUnitPath = `${adsConfig.networkCode}/${this.resolveAdUnitPathDfpId(adsConfig.dfpId)}/${
      adsConfig.dfpSection
    }`;
    (this.videoAdUnitPath = `/${adsConfig.networkCode}/${this.resolveAdUnitPathDfpId(adsConfig.dfpId)}/video`),
      (this.listeners = []);
    this.gptIsReady = false;
    this.ppId = _ppId;
    this.lazyloadAds = false;
    this.usePrebid = window.adsUseDemandManager === 'true';

    if (typeof this.pageSlots === 'undefined') {
      this.pageSlots = {};
    }

    this.pageSlots[uniqueId] = [];

    // @ts-ignore
    this.uniqueIdTemp = `${uniqueId}`;
    this.uniqueId = `-${uniqueId}`;

    this.gptQueue(this.initializeAds);
  }

  public resolveUniqueId = (ad: IAdRaw): string => `${ad?.target}${this.uniqueId}`;

  private resolveAdUnitPathDfpId = (dfpId: string) => {
    let resolveId = dfpId;
    const isSub = isSubDomain(dfpId);

    if (dfpId === 'global' || isSub) {
      let hostname;
/////////////////////

/////////////////////////////////////////////////
///////////////////////////////////////////////

//////////////

      hostname = window.location.host;

///////////////

      resolveId = getDomainFromHostName(hostname);
    }
    return resolveId;
  };

  private setDfpSection(section: string | undefined): string {
    let dfpSection = 'general';

    if (section) {
      dfpSection = section;
    } else {
      if (location.pathname.split('/')[1] !== '' && location.hostname.split('.')[0] == 'www') {
        dfpSection = location.pathname.split('/')[1];
      } else if (location.hostname.split('.')[0] !== 'www') {
        dfpSection = location.hostname.split('.')[0];
      }
    }
    return dfpSection;
  }

  private setDfpId(configDfpId: string): string {
    let dfpId = configDfpId;
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.has('dfp_id')) {
      dfpId = urlParams.get('dfp_id') || configDfpId;
    }
    return dfpId;
  }

  /**
   * Chain of commands pushed to `googletag` execution stack
   *
   * @return void
   */
  private initializeAds(): void {
    this.gptQueue(this.setupPage);
    this.gptQueue(this.setupAdSlots);
    this.gptQueue(this.displayAdSlots); // TODO: Not needed with google lazyload

    const adSlots = pluck('adSlot', this.Ads);
    const verticals = window.aller_ga && window.aller_ga.verticals ? window.aller_ga.verticals : [];
    const dmVerticals = verticals.map((vertical: string) => {
      return vertical
        .replace(/å/g, 'a')
        .replace(/Å/g, 'a')
        .replace(/ä/g, 'a')
        .replace(/Ä/g, 'A')
        .replace(/ö/g, 'o')
        .replace(/Ö/g, 'O');
    });

    this.usePrebid &&
      this.dmQueue(() => {
        // Timeout (ms) for bidrequests is set in DemandManager-dashboard
        pbjs.rp.requestBids({
          gptSlotObjects: adSlots,
          data: {
            inventory: { category: dmVerticals },
            dctr: 'category=' + dmVerticals.join('|category='),
          },
          callback: () => {
            //if callback is left out, a default fallback is run with pubads.refresh.
          },
        });
        pbjs.rp.requestVideoBids({
          adSlotName: this.videoAdUnitPath,
          playerSize: [1920, 1080],
          callback: this.invokeVideoPlayer,
          data: {
            inventory: { category: dmVerticals },
            dctr: 'category=' + dmVerticals.join('|category='),
          },
        });
      });

    this.refreshAds();

    const fetchMarginPercent = isDesktop()
      ? this.adsConfig.fetchMarginPercentDesk
      : this.adsConfig.fetchMarginPercentMob ?? 40;
    let renderMarginPercent = isDesktop()
      ? this.adsConfig.renderMarginPercentDesk
      : this.adsConfig.renderMarginPercentMob ?? 20;

    if (adsAbTest() === 'c') {
      const margins = [5, 10, 15, 20, 25, 30];
      if (!this.renderMargin) {
        this.renderMargin = margins[Math.floor(Math.random() * margins.length)].toString();
      }
      renderMarginPercent = parseInt(this.renderMargin);
    }

    window.googletag.pubads().enableLazyLoad({
      fetchMarginPercent: fetchMarginPercent,
      renderMarginPercent: renderMarginPercent,
    });

    this.refreshAdsWithLazyload();
    isTablet && scaleAds();
  }
  public invokeVideoPlayer(videoBidResult: any, bids: any) {
    window.adsTagUrl = videoBidResult?.adTagUrl;
    window.adsBids = bids;
  }

  /**
   * Queue
   *
   * Helps us push our functions to the google call stack and
   * keep our context.
   *
   * @param fn fn
   * @return void
   */
  public gptQueue(fn: Function): void {
    window.googletag.cmd.push(fn.bind(this));
  }

  /**
   * PrebidJS Queue
   *
   * Helps us push functions to the prebid call stack and keep
   * our context.
   *
   * @param fn fn
   *
   * @return void
   */
  public dmQueue(fn: Function): void {
    pbjs.que.push(() => fn.apply(this));
  }

  /**
   * Since we get the targeting data from outside we really have no idea what they actually contains.
   * @param trg {object}
   * @return void
   */
  private isValidTargeting(trg: ITargeting): boolean {
    return (
      isNotNil(trg) &&
      isNotNil(trg.key) &&
      isNotNil(trg.value) &&
      isString(trg.key) &&
      (isString(trg.value) || Array.isArray(trg.value))
    );
  }

  /**
   * Sets global key values for relevant prebid partner.
   * @param key {string}
   * @param value {string}
   * @param forLW {boolean} Send keyValue to LiveWrapped
   * @return void
   */
  public setGlobalTargeting(key: string, value: string | string[]): void {
    this.gptQueue(() => window.googletag.pubads().setTargeting(key, value));
  }

  /**
   * This method setup ad-settings on a page-level. For instance it
   * iterates window.aller_ga.verticals object and targets verticals
   * that is common for all ads
   * @return void
   */
  private setupPage(): void {
    this.device = getDevice();

    // correlator
    this.setGlobalTargeting('deviceType', this.device);

    let msVerticals: any = [];
    //@ts-ignore
    if (this.adsConfig && this.adsConfig.verticals && this.adsConfig.verticals.length > 0) {
      const path = window.location.pathname.split('/');
      let slug = '';
      let match;

      if (Array.isArray(path)) {
        // Remove the extra empty end
        path.pop();

        // go through all slug parts and look for verticals
        path.map((slugPart) => {
          // Create slug to search for and clean start/end from "/"
          slug = slug + '/' + slugPart;
          slug = slug.replace(/(^\/|\/$)/g, '');

          // Find vertical match by domain and slug
          //@ts-ignore
          match = this.adsConfig.verticals.find((site) => {
            return site.domain === window.location.hostname.replace(/(www-staging.|www.)/, '') && site.slug === slug;
          });
          if (match && match.verticals) {
            msVerticals = [...msVerticals, ...match.verticals];
          }
        });
      }
    }

    let gaVerticals = window.aller_ga && window.aller_ga.verticals ? window.aller_ga.verticals : [];
    gaVerticals = typeof gaVerticals === 'string' ? [gaVerticals] : gaVerticals;

    // Merge verticals and remove duplicates
    /* ts-ignore */
    const verticals = [...new Set([...msVerticals, ...gaVerticals])];

    if (verticals && verticals.length > 0) {
      const vertical_params = verticals.length == 1 ? verticals[0] : verticals;

      this.setGlobalTargeting('Vertikal', vertical_params);

      // set all verticals back on window
      window.aller_ga = window.aller_ga || {};
      window.aller_ga.verticals = verticals;
    }

    /**
     * For sending targeting key/values to GAM from the outside.
     * Expected to have the structure like: [ {key: 'anykey', value: string|integer|array} ]
     */
    if (window.gamTargets && Array.isArray(window.gamTargets)) {
      window.gamTargets.map((trg: any) => {
        if (this.isValidTargeting(trg)) {
          this.setGlobalTargeting(trg.key, trg.value);
        }
      });
    }

    /** Set category and content targeting from current path name */
    const pathComponents = window.location.pathname.split('/');
    this.setGlobalTargeting('url', window.location.hostname);
    pathComponents[1] && this.setGlobalTargeting('url-category', pathComponents[1]);
    pathComponents[2] && this.setGlobalTargeting('url-content', pathComponents[2]);
    /* Set key value for social media user agent */
    (isFacebookInAppBrowser() || isPinterestInAppBrowser() || isTikTokInAppBrowser() || isInstagramInAppBrowser()) &&
      this.setGlobalTargeting('user-agent', 'social');
    /* Set key value for RefferedPage from search engine */
    isRefferedPage() && this.setGlobalTargeting('user-agent', 'search');
    /* Set key value for Google Discover */
    isGoogleDiscover() && this.setGlobalTargeting('user-agent', 'discover');
    /* Set key value for pushpushgo notification */
    isComingFromPushPushGo() && this.setGlobalTargeting('user-agent', 'push');
    /* Set key value for category */
    isCategoryPage() && this.setGlobalTargeting('category', '1');
    /* Set key value for app user agent */
    isApp() && this.setGlobalTargeting('user-agent', 'app');
    this.setGlobalTargeting('lazyload', adsAbTest() === 'c' ? `c${this.renderMargin}` : 'a');
    if (adsAbTest() === 'c') {
      this.setGlobalTargeting('RenderMargin', this.renderMargin);
      this.setGlobalTargeting('connectionRtt', getConnectionType());
    }

    this.setGlobalTargeting('goog_abt', window.adsAbGroup || 'c');

    this.ppId && window.googletag.cmd.push(() => window.googletag.pubads().setPublisherProvidedId(this.ppId));
  }

  /**
   * Uses data from the back-end which is exposed to the global window object
   * under the namespace `DfpAds`.
   * The resulting data is all ads that we had in our agilis solum layout
   * for the current template.
   *
   * This method iterates through each ad and runs checks to see what sort
   * of ad we are dealing with and sets up size mapping and defines the
   * actual ad slot using the googletag api.
   *
   * @return void
   */
  private setupAdSlots(): void {
    // @ts-ignore
    this.pageSlots[this.uniqueIdTemp] = this.Ads.map((ad) => {
      // Define the slot if a corresponding html node is found
      const domId = `${ad.target}${this.uniqueId}`;
      ad.adSlot = window.googletag.defineSlot(this.adUnitPath, [...ad.sizes?.mobile, ...ad.sizes?.desktop], domId);

      //Create a size mapping to declare which viewport which ad sizes will be shown to
      const mapping = window.googletag
        .sizeMapping()
        .addSize([0, 0], ad.sizes.mobile)
        .addSize(DESKTOP_BREAKPOINT, ad.sizes.desktop)
        .build();

      ad.adSlot
        .defineSizeMapping(mapping)
        .addService(window.googletag.pubads())
        .setTargeting('pos', adjustPlacement(ad.placement));

      log('### DM domId ', domId, this.adUnitPath);

      ad.adSlot.setTargeting('demandmanager', '1');
      return ad;
    });

    this.usePrebid && this.dmQueue((): void => pbjs.setConfig({ enableSendAllBids: false }));
    this.usePrebid && this.dmQueue((): void => pbjs.setTargetingForGPTAsync());

    window.googletag.pubads().enableSingleRequest();

    /**
     * Enables the GPT services that made use of while defining our ad slots
     * on the page
     */
    window.googletag.enableServices();
  }

  /**
   * We iterate through each defined ad in our object DfpAds.Ads and run
   * the GPT method display() to fetch the ads, note.. we aren't loading them
   * yet.
   *
   * @return void
   */
  private displayAdSlots(): void {
    return this.Ads.forEach((ad): void => {
      window.googletag.display(`${ad.target}${this.uniqueId}`);
    });
  }

  /**
   * refresh ads without lazyload
   *
   * @return void
   */
  public refreshAds(): void {
    this.lazyloadAds = false;
    this.Ads.forEach((ad: IAdRaw): void => {
      if (!this.lazyloadAds && withOutLazyloadSlots(ad)) {
        if (this.usePrebid) {
          refreshPrebidAd(ad);
        } else {
          window.googletag.pubads().refresh([ad.adSlot], { changeCorrelator: false });
        }
      }
    });
  }
  /**
   * refresh ads with lazyload
   *
   * @return void
   */
  public refreshAdsWithLazyload(): void {
    this.lazyloadAds = true;
    this.Ads.forEach((ad: IAdRaw): void => {
      if (this.lazyloadAds && !withOutLazyloadSlots(ad)) {
        if (this.usePrebid) {
          refreshPrebidAd(ad);
        } else {
          window.googletag.pubads().refresh([ad.adSlot], { changeCorrelator: false });
        }
      }
    });
  }
}

export default new AdsManager();
