import waitFor from '../helpers/waitfor';
import getTcfConsent from './tcfv2';
import log from '../helpers/log';

const initTcf = async (): Promise<void> => {
  log('initTcf ...');

  try {
    const r = await getTcfConsent();
    log('getTcfConsent resolved', r);
  } catch (e) {
    console.error('Unable to retrieve tcf consent');
  }

  log('initTcf DONE');
};

const initConsent = async (): Promise<void> => waitFor(() => typeof window.__tcfapi !== 'undefined').then(initTcf);

export default initConsent;
