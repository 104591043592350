import log from '../helpers/log';

const statsCollectorEndpoint = `${process.env.STATS_COLLECTOR}`;

const siteMapping = {
    residencemagazine: 'residence',
}

const getSiteFromDomain = site => siteMapping[site] ?? site;

export function AdStats(site, device)  {

    let _device = device;
    let _site = getSiteFromDomain(site);
    let _stats = [];

    const add = stats => {
        if (Array.isArray(stats) && stats.length) {
            _stats = [..._stats, ...stats];
        }
        return _stats;
    }

    const payload = () => ({
        clientTsInMs: Date.now(),
        //device: _device,
        site: _site,
        stats: [ ..._stats],
        //userAgent: navigator.userAgent
    })

    const toJSON = () => {
        try {
            const response = JSON.stringify(payload())
            return response;
        }
        catch (e) {}
    }

    const appendFromJSON = payload => {
        const parsed = JSON.parse(payload);
        _stats = [ ..._stats, ...parsed.stats]
    }

    const push = () => {
        if (_stats.length) {
            // TODO: Need to improve this bit to make sure we don't get corrupt data
            const body = toJSON();
            if (body) {
                _stats = [];
                fetch(statsCollectorEndpoint, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json'
                    },
                    body,
                })
                .catch(() => {
                    log(`AdStats::push - Unable to push stats, re-adding.`)
                    appendFromJSON(body)
                })
            }
            else {
                log(`AdStats::push - unable to retrieve retrieve payload..`)
            }

        }
        else {
            log(`AdStats::push - no stats available for sending to API.`)
        }
    }

    return {
        all: () => _stats,
        add,
        push,
        toJSON,
        payload,
    }
}